<template>
    <div id="wrapper">
        <div v-if="!loading" id="login">
            <div class="container-fluid">
                <div class="row h-50">
                    <div class="col-sm-12 h-100 d-table">
                        <div class="d-table-cell align-middle login_div">
                            <div class="form_wrapper">
                                <div class="form_container">
                                    <div class="row">
                                        <div class="col-sm-12 pr-40">
                                            <a href="" class="logo text-center"><img src="@/assets/images/talentturbo1.png"></a>
                                            <h4 class="text-center pr-10">Login</h4>
                                        </div>
                                    </div>
                                    
                                    <div class="row clearfix">
                                    <div class="col_half">
                                        <div class="title_container">
                                            <h2>Social Network</h2>
                                        </div>
                                        <div class="social_btn fb">
                                            <a href="#" @click="authenticate('facebook')"><span><i class="fa fa-facebook" aria-hidden="true"></i></span>Sign in with Facebook</a>
                                        </div>
                                        <div class="social_btn gplus">
                                            <a href="#" @click="authenticate('google')"><span><i class="fa fa-google-plus" aria-hidden="true"></i></span>Sign in with Google</a>
                                        </div>
                                       
                                        <div class="social_btn tw">
                                            <a href="#" @click="linkedinAuthenticate()"><span><i class="fa fa-linkedin" aria-hidden="true"></i></span>Sign In with Linkedin</a>
                                        </div>
                                    </div>
                                    <div class="d-block d-sm-none Or-line"></div>
                                    <div class="col_half last">
                                        <form @submit.prevent="performLogin()" id="login-form" class="tt_form">
                                                <div class="row form-group">
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <label class="labl">Email</label>
                                                            </div>
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input-group required-field-block">
                                                                    <input v-model.lazy="login.email" type="text" name="email" maxlength="80" class="form-control" autocomplete="off" :class="{ 'is-invalid': $v.login.email.$error }" @blur="$v.login.email.$touch">
                                                                    <span class="required-icon"></span>
                                                                </div>
                                                                <label v-if="$v.login.email.$error && !$v.login.email.required" class="text-danger">Please enter Email</label>
                                                                <label v-if="$v.login.email.$error && !$v.login.email.email" class="text-danger">Please enter a valid email</label>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <label class="labl">Password</label>
                                                            </div>
                                                            <div class="col-md-12 col-sm-12 col-xs-12 ">
                                                                <div class="input-group required-field-block">
                                                                    <input v-model="login.password" type="password" name="password" maxlength="45" class="form-control" autocomplete="off">
                                                                    <span class="required-icon"></span>
                                                                </div>
                                                                <label v-if="$v.login.password.$error && !$v.login.password.required" class="text-danger">Please enter the password</label>
                                                                <label v-if="$v.login.password.$error && !$v.login.password.minLength" class="text-danger">Password must have 6 characters</label>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <label class="labl">User Type</label>
                                                            </div>
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input-group required-field-block">
                                                                    <select class="filter form-control" id="userType" v-model="login.userType">
                                                                        <option value="">Select</option>
                                                                        <option value="admin">Admin</option>
                                                                        <option value="candidate">Candidate</option>
                                                                        <option value="interviewer">Interviewer</option>
                                                                        <option value="recruiter">Recruiter</option>
                                                                        <option value="referral">Referral</option>
                                                                    </select>
                                                                    <span class="required-icon mr-15"></span>
                                                                </div>
                                                                <label v-if="$v.login.userType.$error && !$v.login.userType.required" class="text-danger">Please select the user Type</label>
                                                            </div>
                                                        </div>
                                                        <div class="row m-t-15">
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                                                                    <label class="form-check-label" style="font-weight: normal;" for="rowchckbox1">Remember Me</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <a href="" style="display:none" class="btn btn-primary ">Registration</a>
                                                        <button type="submit" class="btn btn-primary  float-right">Login</button>
                                                        <span>
                                                            <router-link :to="{ name: 'forgotpassword' }">Forgot Password ? </router-link>
                                                        </span><br>
                                                        <span v-if="login.key != null && login.userType == 'candidate'">Not a registered candidate? <router-link :to=" {name: 'candidateregister-publicportal', params: {key: login.key , jobId:login.jobId}} ">Register Now</router-link> or Upload CV</span>
                                                        <span v-if="login.key != null && login.userType == 'referral'">Not a registered referral? <router-link :to=" {name: 'referralregister', params: {key: login.key , jobId:login.jobId}} ">Register Now</router-link></span>
                                                    </div>
                                                </div>
                                            </form>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
        <div v-if="loading" id="globalapploader" class="loader"></div> 
    </div>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import CryptoJS from 'crypto-js';
import iziToast from 'izitoast';
export default {
    data() {
        return {
            docSrc:"https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" + this.$store.getters.getLinkedinClientId + "&redirect_uri=" + this.$store.getters.getLinkedinRedirectUrl + "&state=388169727158&scope=r_liteprofile%20r_emailaddress",
            login: {
                email: '',
                password: '',
                key: this.$route.params.key,
                userType: '',
                //userType: this.$route.params.userType,
                jobId: this.$route.params.jobId ? this.$route.params.jobId : 0,
                referralId: this.$route.params.referralId ? this.$route.params.referralId : 0
            },
            loginerrors: [],
            loading:false,
            candid:''
            //socialReferralId: this.$route.params.referralId ? this.$route.params.referralId : 0
        }

    },
    validations: {
        login: {
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            },
            userType: {
                required
            }
        }
    },
    
    mounted() {
         this.loadscreens();
    },
    methods: {
         loadexit(){
            this.loading = true;
            let queryString = window.location.search;
            let urlParam = new URLSearchParams(queryString);
            let token = urlParam.get('token');
            if(token === null || token === " " ){
                window.location.href = process.env.VUE_APP_MAIN_URL;
            }
        },
        loadscreens() {
            this.loading = true
            let queryString = window.location.search;
            let urlParam = new URLSearchParams(queryString);
            let token1 = urlParam.get('t');
            let decTokn = CryptoJS.enc.Base64.parse(token1).toString(CryptoJS.enc.Utf8)
            let decToken = CryptoJS.AES.decrypt(decTokn, process.env.VUE_APP_DECRYPT_KEY).toString(CryptoJS.enc.Utf8)
            let token = JSON.parse(decToken)
             if(token === null || token === " " ){
                window.location.href = process.env.VUE_APP_MAIN_URL; 
                return;            
            }
            
            let loginKey = urlParam.get('l');
            let candid = urlParam.get('cid');
            if(candid!=null && candid!=undefined && candid!=''){
            localStorage.setItem('loginCandid', candid);
            localStorage.setItem('loginKey', loginKey);
            }
            else{
            var candiateId = 0;
                localStorage.setItem('loginCandid', candiateId);
                localStorage.setItem('loginKey', loginKey);
            }
            let usrType = urlParam.get('u');
            let decusrTyp = CryptoJS.enc.Base64.parse(usrType).toString(CryptoJS.enc.Utf8)
            let UserType = CryptoJS.AES.decrypt(decusrTyp, process.env.VUE_APP_DECRYPT_KEY).toString(CryptoJS.enc.Utf8)
            let usertype = JSON.parse(UserType)
            let Params = { "authToken": token ,"userType":usertype }
            if(Params !=null ){
                axios.post(this.$store.getters.getAPIBasePath + '/userresource/react/openapi', Params)
                        .then(response => {
                            this.loading = false
                            let result = response.data;                   
                            if (result.result) {
                                localStorage.setItem('candidateContactAccess', result.data.candidateContactAccess);
                                localStorage.setItem('salaryNegotiationAccess', result.data.salaryNegotiationAccess);
                                localStorage.setItem('offerLetterAccess', result.data.offerLetterAccess);
                                localStorage.setItem('vendorClientInfoAccess', result.data.vendorClientInfoAccess);
                                localStorage.setItem('currencyId', result.data.currencyId);
                                localStorage.setItem('workAuthorization', result.data.workAuthorization);
                                localStorage.setItem('countryId', result.data.countryId);
                                localStorage.setItem('companyLogo', result.data.companyLogo);
                                localStorage.setItem('accountType', result.data.accountType);
                                const defaultredirects = this.$store.getters.getDefaultRedirects;
                                this.$store.dispatch("setupLogin", result).then(() => {
                                    if (result.data.userType == "interviewer") {
                                        this.$router.push({ path: defaultredirects.interviewer });
                                    } else if (result.data.userType == "candidate") {
                                        this.$router.push({ name: "candidatedashboard", params: { jobId: this.login.jobId } });
                                        // if (localStorage.getItem('clientAccountId') != null) {
                                        //     let candInfo = {
                                        //         recruiterId: localStorage.getItem('recruiterId'),
                                        //         ttClientAccountId: localStorage.getItem('clientAccountId'),
                                        //         companyId: localStorage.getItem('companyId'),
                                        //         candidateId: this.$store.getters.currentUser.profileId,
                                        //         email: this.login.email
                                        //     };
                                        //     axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/other/company', candInfo)
                                        //         .then(response => {
                                        //             //console.log(response);
                                        //         })
                                        //         .catch(error => {
                                        //             console.log(error);
                                        //         });
                                        // }
                                    } else if (result.data.userType == "admin") {
                                        //this.$router.push({ path: defaultredirects.admin });

                                        if (result.data.isSuperAdmin) {
                                            this.$router.push({ path: defaultredirects.superadmin });
                                        } else {
                                            this.$router.push({ path: defaultredirects.admin });
                                        }

                                    } else if (result.data.userType == "guest") {
                                        this.$router.push({ path: defaultredirects.guest,params: { id:candiateId,key:loginKey}});
                                    } else if (result.data.userType == "referral") {
                                        this.$router.push({ path: defaultredirects.referral, params: { jobId: this.login.jobId } });
                                        localStorage.setItem('loginJobId', this.login.jobId);
                                        // if (localStorage.getItem('clientAccountId') != null) {
                                        //     let referralInfo = {
                                        //         recruiterId: localStorage.getItem('recruiterId'),
                                        //         ttClientAccountId: localStorage.getItem('clientAccountId'),
                                        //         companyId: localStorage.getItem('companyId'),
                                        //         referralId: this.$store.getters.currentUser.profileId,
                                        //         email: this.login.email
                                        //     };
                                        //     axios.post(this.$store.getters.getAPIBasePath + '/referralresource/add/referral/other/company', referralInfo)
                                        //         .then(response => {
                                        //         })
                                        //         .catch(error => {
                                        //             console.log(error);
                                        //         });
                                        // }
                                    } else {
                                        if (result.data.clientServiceExpired == true) {
                                            iziToast.info({
                                                title: '',
                                                message: this.$config.AccExp,
                                                position: 'topRight'
                                            });
                                            return;
                                        }
                                        if (result.data.ttClientAccountId != null) {
                                            localStorage.setItem('clientAccountId', result.data.ttClientAccountId);
                                        }
                                        //if (result.data.recruitOwner) {
                                        if (result.data.recruitType == 2 || result.data.recruitType == 3) { // this for recruiter manager
                                            if(result.data.accountType == 3){
                                                this.$router.push({ path: defaultredirects.corpadmin });
                                            }
                                            else{
                                                this.$router.push({ path: defaultredirects.recruiteradmin });
                                            }
                                        } else {
                                            this.$router.push({ path: defaultredirects.recruiter });
                                        }
                                    }
                                });
                            } else {
                                iziToast.info({
                                    message: result.message,
                                    position: 'topRight'
                                });

                            }
                        })
                        .catch(error => {
                            console.log(error);
                            iziToast.error({
                                title: 'Error Occured',
                                message: this.$config.TryAg,
                                position: 'topRight'
                            });
                        });
            }
        },
        performLogin() {
            this.$store.dispatch("doLogout");
            this.$v.login.$touch();
            if (this.$v.login.$invalid) {
                return false;
            } else {
                this.addLogActivity();
                axios.post(this.$store.getters.getAPIBasePath + '/userresource/authenticate', this.login)
                    .then(response => {
                        let result = response.data;                   
                        if (result.result) {
                            localStorage.setItem('candidateContactAccess', result.data.candidateContactAccess);
                            localStorage.setItem('salaryNegotiationAccess', result.data.salaryNegotiationAccess);
                            localStorage.setItem('offerLetterAccess', result.data.offerLetterAccess);
                            localStorage.setItem('vendorClientInfoAccess', result.data.vendorClientInfoAccess);
                            localStorage.setItem('currencyId', result.data.currencyId);
                            localStorage.setItem('workAuthorization', result.data.workAuthorization);
                            localStorage.setItem('countryId', result.data.countryId);
                            localStorage.setItem('companyLogo', result.data.companyLogo);
                            localStorage.setItem('accountType', result.data.accountType);
                            const defaultredirects = this.$store.getters.getDefaultRedirects;
                            this.$store.dispatch("setupLogin", result).then(() => {
                                if (result.data.userType == "interviewer") {
                                    this.$router.push({ path: defaultredirects.interviewer });
                                } else if (result.data.userType == "candidate") {
                                    this.$router.push({ name: "candidatedashboard", params: { jobId: this.login.jobId } });
                                    // if (localStorage.getItem('clientAccountId') != null) {
                                    //     let candInfo = {
                                    //         recruiterId: localStorage.getItem('recruiterId'),
                                    //         ttClientAccountId: localStorage.getItem('clientAccountId'),
                                    //         companyId: localStorage.getItem('companyId'),
                                    //         candidateId: this.$store.getters.currentUser.profileId,
                                    //         email: this.login.email
                                    //     };
                                    //     axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/other/company', candInfo)
                                    //         .then(response => {
                                    //             //console.log(response);
                                    //         })
                                    //         .catch(error => {
                                    //             console.log(error);
                                    //         });
                                    // }
                                } else if (result.data.userType == "admin") {
                                    //this.$router.push({ path: defaultredirects.admin });
                                     if (result.data.isSuperAdmin) {
                                            this.$router.push({ path: defaultredirects.superadmin });
                                    } else {
                                        this.$router.push({ path: defaultredirects.admin });
                                    }
                                } else if (result.data.userType == "guest") {
                                        this.$router.push({ path: defaultredirects.guest,params: { id:candiateId,key:loginKey}});
                                } else if (result.data.userType == "referral") {
                                    this.$router.push({ path: defaultredirects.referral, params: { jobId: this.login.jobId } });
                                    localStorage.setItem('loginJobId', this.login.jobId);
                                    // if (localStorage.getItem('clientAccountId') != null) {
                                    //     let referralInfo = {
                                    //         recruiterId: localStorage.getItem('recruiterId'),
                                    //         ttClientAccountId: localStorage.getItem('clientAccountId'),
                                    //         companyId: localStorage.getItem('companyId'),
                                    //         referralId: this.$store.getters.currentUser.profileId,
                                    //         email: this.login.email
                                    //     };
                                    //     axios.post(this.$store.getters.getAPIBasePath + '/referralresource/add/referral/other/company', referralInfo)
                                    //         .then(response => {
                                    //             //console.log(response);
                                    //         })
                                    //         .catch(error => {
                                    //             console.log(error);
                                    //         });
                                    // }
                                } else {
                                    if (result.data.clientServiceExpired == true) {
                                        iziToast.info({
                                            title: '',
                                            message: this.$config.AccExp,
                                            position: 'topRight'
                                        });
                                        return;
                                    }
                                    if (result.data.ttClientAccountId != null) {
                                        localStorage.setItem('clientAccountId', result.data.ttClientAccountId);
                                    }
                                    if (result.data.recruitOwner) {
                                        if(result.data.accountType == 3){
                                                this.$router.push({ path: defaultredirects.corpadmin });
                                            }
                                            else{
                                                this.$router.push({ path: defaultredirects.recruiteradmin });
                                            }
                                    } else {
                                        this.$router.push({ path: defaultredirects.recruiter });
                                    }
                                }
                            });
                        } else {
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.TryAg,
                            position: 'topRight'
                        });
                    });
            }
        },
        addLogActivity: function() {
            this.logActivityData = {
                entityName: 'Login',
                actionName: 'Login',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {
                    localStorage.setItem('candIdForActivity', response.data.id);
                })
                .catch(error => {

                });
        },
        linkedinAuthenticate() {
		    window.location.href = this.docSrc;
	  	},
        authenticate(provider) {
        let self = this;
        this.$auth
        .authenticate(provider)
        .then(function () {
          let token = self.$auth.getToken();
          console.log("token", self.$auth);
          localStorage.removeItem("vue-authenticate.vueauth_token");
          if (provider === "google") {
            self.axios
              .get(
                "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" +
                  token
              )
              .then((response) => {
                console.log(response);
                let socialLoginData = {
                    socialLoginId: response.data.sub,
                    email: response.data.email,
                    firstName: response.data.given_name,
                    lastName: response.data.family_name,
                    socialLoginProvider: "google",
                    userType: "candidate",
                    jobId: self.login.jobId
                };
                self.doServerAuthentication(socialLoginData);
              })
              .catch((error) => {
                console.log(error);
                iziToast.error({
                  title: "Error Occured",
                  message: this.$config.TryAg,
                  position: "topRight",
                });
              });
          }
          if (provider === "facebook") {
            self.axios
              .get(
                "https://graph.facebook.com/v3.0/me?fields=id,first_name,last_name,email",
                {
                  params: { access_token: token },
                }
              )
              .then(function (response) {
                  console.log(response);
                let socialLoginData = {
                    socialLoginId: response.data.id,
                    // email: null,
                    email: response.data.email,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    socialLoginProvider: "facebook",
                    userType: "candidate"
                };
                self.doServerAuthentication(socialLoginData);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          iziToast.error({
          	title: "Error Occured",
          	message: this.$config.TryAg,
          	position: "topRight",
          });
        });
    },
    doServerAuthentication(socialData) {
      console.log("socialData", socialData);
      if(socialData.email==null)
        this.$router.push({ name: "intermediatepage", params: { socialData : socialData } });
      axios
        .post(
          this.$store.getters.getAPIBasePath +
            "/userresource/social/authenticate",
          socialData
        )
        .then((response) => {
        //   let result = response.data;
        //   console.log("Result...............", result);
        //   if (result.result) {
        //     this.$store.dispatch("setupLogin", result).then(() => {
        //       console.log("setupLogin", result);
        //       this.$router.push({ name: "candidatedashboard" });
        //       // if (result.data.userType == "candidate") {
        //       //     this.$router.push({ name: "candidatedashboard", params: { jobId: 1 } });
        //       // }
        //     });
        //   } else {
        //     iziToast.info({
        //       message: result.message,
        //       position: "topRight",
        //     });
        //   }
          let result = response.data;
          const defaultredirects = this.$store.getters.getDefaultRedirects;
          console.log("Result...............", result);
          if (result.result) {
            this.$store.dispatch("setupLogin", result).then(() => {
              console.log("setupLogin", result);
              console.log(result.data.userType);
              //this.$router.push({ name: "candidatedashboard" });
              if (result.data.userType == "candidate") {
                  this.$router.push({ name: "candidatedashboard" });
                  //this.$router.push({ name: "candidatedashboard", params: { jobId: 1 } });
              }
              else if(result.data.userType == "referral") {
                  console.log(this.login.jobId);
                  this.$router.push({ path: defaultredirects.referral, params: { jobId: this.login.jobId } });
                //this.$router.push({ path: defaultredirects.referral });
              }
            });
          } else {
            iziToast.warning({
              message: result.message,
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          iziToast.error({
            title: "Error Occured",
            message: this.$config.TryAg,
            position: "topRight",
          });
        });
    },
    }
}

</script>
<style type="text/css" scoped>

</style>
